* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content-wrapper {
    flex: 1;
}
